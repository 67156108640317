import React from 'react';

import Layout from '../../components/Layout';
import CourseFeatures from '../../components/CourseFeatures';
import pic1 from '../../assets/images/courses/DevOps-Engineer-Training.jpg';
import 'bootstrap/dist/css/bootstrap.min.css';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const DevOpsTrng = () => (
  <Layout>
    <div id="main" >
      <div className="inner">
        <article className="box"> 
        <h1>DevOps Engineer Training</h1>
        <span className="image main">
          <img src={pic1} alt="" />
        </span>
        </article>
        <article className="box">
        
        
        <Container>
          <div className="inner">
          <p>
            Our comprehensive <b>DevOps Engineer</b> Training Program makes you proficient 
            in skills to work with front-end web technologies.
            This course is designed to help you master the most in-demand and critical skills for becoming a FullStack developer. 
            Especially if you're going for a job interview or have a Project that needs your best performance.
          </p>
          </div>
          <article>
            <p>
              With a balanced mix of theoretical concepts and hands-on exercises, comibined with a capstone project that is as
              close to the real things as it gets, we promise to equip you with the necessary skills that will enable you to perform
              as a full stack developer.This ia an intensive 16 Week intensive  program followed by an opportunity for a 10 – week co-op program.
            </p><br />
          </article>
        </Container>
        <Container>
          <Row>
            <Col>What this course offers
              <ListGroup>
                    <ListGroup.Item><span className="icon fa-check-square-o">  A huge project that will teach you everything you need to know to get started as a full-stack developer. </span></ListGroup.Item> 
                    <ListGroup.Item><span className="icon fa-check-square-o">  In-depth understanding of concepts of system design, OOP, Design Patterns and Distributed Systems </span></ListGroup.Item> 
                    <ListGroup.Item><span className="icon fa-check-square-o">  Awesome Quality Content in the form of virtual sessions and study material. </span></ListGroup.Item> 
                    <ListGroup.Item><span className="icon fa-check-square-o">  Strcutured rigourous learning plan with practical sessions </span></ListGroup.Item> 
              </ListGroup>
            </Col>
          </Row><br />
          <Row>
            <Col>Who this course is for 
              <ListGroup>
                    <ListGroup.Item><span className="icon fa-check-square-o">  This course is for students fresh out of college as well as experienced developers. </span></ListGroup.Item> 
                    <ListGroup.Item><span className="icon fa-check-square-o">  Anyone who wants to expand their skillset or become a freelance developer. </span></ListGroup.Item> 
                    <ListGroup.Item><span className="icon fa-check-square-o">  Programmers who are not feeling confident about their existing  skills</span></ListGroup.Item> 
                    <ListGroup.Item><span className="icon fa-check-square-o">  Anyone who wants to learn real-world skills and build real-world applications. </span></ListGroup.Item> 
                    <ListGroup.Item><span className="icon fa-check-square-o">  Anyone who wants to make a career change. </span></ListGroup.Item> 
              </ListGroup>
            </Col>
          </Row>
        </Container><br />
        <CourseFeatures /><br />
        

        <Accordion defaultActiveKey="0">
          <Card bg="info" text="black">
            <Card.Header as="h5">COURSE OUTLINE</Card.Header>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="0">
              <span className="icon major style1 h5 fa-plus-circle" >    1.	Course Introduction (Week 1)</span>         
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
              <ListGroup>
              <ListGroup.Item>1.4	Installation of DevOps Tools on cloud
                  <ListGroup>
                    <ListGroup.Item>1.4.1	Git</ListGroup.Item>
                    <ListGroup.Item>1.4.2	Docker</ListGroup.Item>
                    <ListGroup.Item>1.4.3	Selenium</ListGroup.Item>
                    <ListGroup.Item>1.4.4	Maven</ListGroup.Item>
                    <ListGroup.Item>1.4.5	Jenkins</ListGroup.Item>
                    <ListGroup.Item>1.4.6	Puppet</ListGroup.Item>
                    <ListGroup.Item>1.4.7	Ansible</ListGroup.Item>
                    <ListGroup.Item>1.4.8	Kubernetes</ListGroup.Item>
                    <ListGroup.Item>1.4.9	Nagios</ListGroup.Item>
                  </ListGroup>
                </ListGroup.Item>
                <ListGroup.Item>1.1	What is DevOps?</ListGroup.Item>
                <ListGroup.Item>1.2	DevOps Ecosystem, DevOps Engineer Skills and DevOps Market Trends</ListGroup.Item>
                <ListGroup.Item>1.3	DevOps Engineer Role – Salary, Career Path, Responsibilities</ListGroup.Item>
                <ListGroup.Item>1.4	Installation of DevOps Tools on cloud
                  <ListGroup>
                    <ListGroup.Item>1.4.1	Git</ListGroup.Item>
                    <ListGroup.Item>1.4.2	Docker</ListGroup.Item>
                    <ListGroup.Item>1.4.3	Selenium</ListGroup.Item>
                    <ListGroup.Item>1.4.4	Maven</ListGroup.Item>
                    <ListGroup.Item>1.4.5	Jenkins</ListGroup.Item>
                    <ListGroup.Item>1.4.6	Puppet</ListGroup.Item>
                    <ListGroup.Item>1.4.7	Ansible</ListGroup.Item>
                    <ListGroup.Item>1.4.8	Kubernetes</ListGroup.Item>
                    <ListGroup.Item>1.4.9	Nagios</ListGroup.Item>
                  </ListGroup>
                </ListGroup.Item>
              </ListGroup>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="1">
              <span className="icon major style1 h5 fa-plus-circle" >    2.	Git, Jenkins and Maven Integration (Week 2, Week 3)</span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
              <Card.Body>
              <ListGroup>
              <ListGroup.Item>2.1	Introduction to Version Control
                  <ListGroup>
                    <ListGroup.Item>2.1.1	What is version control?</ListGroup.Item>
                    <ListGroup.Item>2.1.2	Types of Version Control System</ListGroup.Item>
                    <ListGroup.Item>2.1.3	Git Lifecycle and Common Git commands</ListGroup.Item>
                    <ListGroup.Item>2.1.4	Working with Branches in Git</ListGroup.Item>
                    <ListGroup.Item>2.1.5	Git Workflow</ListGroup.Item>
                  </ListGroup>
                </ListGroup.Item>
                <ListGroup.Item>2.2	Introduction to Jenkins (With Architecture)</ListGroup.Item>
                <ListGroup.Item>2.3	Introduction to Maven</ListGroup.Item>
                <ListGroup.Item>2.4	Introduction to Continuous Integration</ListGroup.Item>
                <ListGroup.Item>2.5	Jenkins Master Slave Architecture</ListGroup.Item>
                <ListGroup.Item>2.6	Understanding CI/CD Pipelines</ListGroup.Item>
                <ListGroup.Item>2.7	Creating an end to end automated CI/CD Pipeline</ListGroup.Item>
                <ListGroup.Item>2.8	 Hands-on Exercise 
                  <ListGroup>
                    <ListGroup.Item>2.8.1	Git Life cycle Commands</ListGroup.Item>
                    <ListGroup.Item>2.8.2	Pushing Code to Github</ListGroup.Item>
                    <ListGroup.Item>2.8.3	Stashing Code in git</ListGroup.Item>
                    <ListGroup.Item>2.8.4	Creating, Deleting Git Branches</ListGroup.Item>
                    <ListGroup.Item>2.8.5	Reverting a Push to GitHub</ListGroup.Item>
                    <ListGroup.Item>2.8.6	Merging branches using git merge</ListGroup.Item>
                    <ListGroup.Item>2.8.7	Merging branches using git rebase</ListGroup.Item>
                    <ListGroup.Item>2.8.8	Resolving merge conflicts using git merge tool</ListGroup.Item>
                    <ListGroup.Item>2.8.9	Build and automation of Test using Jenkins and Maven</ListGroup.Item>
                    <ListGroup.Item>2.8.10	Build the pipeline of jobs using Jenkins</ListGroup.Item>
                    <ListGroup.Item>2.8.11	Create a pipeline script to deploy an application over the tomcat server</ListGroup.Item>
                  </ListGroup>
                </ListGroup.Item>
              </ListGroup>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="2">
              <span className="icon major style1 h5 fa-plus-circle" >    3.	Containerization with Docker: Ecosystem and Networking (Week 4, Week 5)</span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="2">
              <Card.Body>
              <ListGroup>
                <ListGroup.Item>3.1	Introduction to Docker Ecosystem</ListGroup.Item>
                <ListGroup.Item>3.2	Understanding Docker Lifecycle</ListGroup.Item>
                <ListGroup.Item>3.3	Common Docker Operations</ListGroup.Item>
                <ListGroup.Item>3.4	Creating Custom Docker Images using Dockerfile</ListGroup.Item>
                <ListGroup.Item>3.5	Managing Containers</ListGroup.Item>
                <ListGroup.Item>3.6	Introduction to Docker Networking</ListGroup.Item>
                <ListGroup.Item>3.7	Container Orchestration Tools</ListGroup.Item>
                <ListGroup.Item>3.8	Hands-on Exercise 
                  <ListGroup>
                    <ListGroup.Item>3.8.1	Common Docker Operations</ListGroup.Item>
                    <ListGroup.Item>3.8.2	Creating a DockerHub Account</ListGroup.Item>
                    <ListGroup.Item>3.8.3	Committing Changes to a Container</ListGroup.Item>
                    <ListGroup.Item>3.8.4	Pushing container to DockerHub</ListGroup.Item>
                    <ListGroup.Item>3.8.5	Creating Local Image Repository</ListGroup.Item>
                    <ListGroup.Item>3.8.6	Building an Image using Dockerfile</ListGroup.Item>
                    <ListGroup.Item>3.8.7	Creating Docker Volumes</ListGroup.Item>
                    <ListGroup.Item>3.8.8	Using Docker Compose to deploy multiple containers</ListGroup.Item>
                    <ListGroup.Item>3.8.9	Deploying a Multi Node Cluster using Docker Swarm</ListGroup.Item>
                    <ListGroup.Item>3.8.10	Deploying a multi-service app on Docker Swarm</ListGroup.Item>
                  </ListGroup>
                </ListGroup.Item>
              </ListGroup>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="3">
              <span className="icon major style1 h5 fa-plus-circle" >    4.	Continuous Deployment: Configuration Management with Puppet (Week 6, Week 7)</span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="3">
              <Card.Body>
              <ListGroup>
                <ListGroup.Item>4.1	Need of Configuration Management</ListGroup.Item>
                <ListGroup.Item>4.2	Configuration Management Tools – Puppet</ListGroup.Item>
                <ListGroup.Item>4.3	Puppet Architecture</ListGroup.Item>
                <ListGroup.Item>4.4	Puppet Installation and Configuration</ListGroup.Item>
                <ListGroup.Item>4.5	Puppet Master and Agent Setup</ListGroup.Item>
                <ListGroup.Item>4.6	Puppet Modules</ListGroup.Item>
                <ListGroup.Item>4.7	Applying configuration using Puppet</ListGroup.Item>
                <ListGroup.Item>4.8	Hands-on Exercise 
                  <ListGroup>
                    <ListGroup.Item>4.8.1	Setting up Master Slave on AWS</ListGroup.Item>
                    <ListGroup.Item>4.8.2	Testing Connection of nodes with Puppet</ListGroup.Item>
                    <ListGroup.Item>4.8.3	Creating a Manifest</ListGroup.Item>
                    <ListGroup.Item>4.8.4	Deploying Manifest on Node</ListGroup.Item>
                    <ListGroup.Item>4.8.5	Creating a Module</ListGroup.Item>
                    <ListGroup.Item>4.8.6	Deploying sample software on nodes using Puppet Modules and Manifests</ListGroup.Item>
                    <ListGroup.Item>4.8.7	Implementing a File Server Module on Puppet</ListGroup.Item>
                  </ListGroup>
                </ListGroup.Item>
              </ListGroup>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="4">
              <span className="icon major style1 h5 fa-plus-circle" >    5.	Configuration Management with Ansible (Week 8, Week 9)</span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="4">
              <Card.Body>
              <ListGroup>
                <ListGroup.Item>5.1	What is Ansible?</ListGroup.Item>
                <ListGroup.Item>5.2	Ansible vs Puppet</ListGroup.Item>
                <ListGroup.Item>5.3	Ansible Architecture</ListGroup.Item>
                <ListGroup.Item>5.4	Ansible Installation and Configuration</ListGroup.Item>
                <ListGroup.Item>5.5	Ansible Playbook</ListGroup.Item>
                <ListGroup.Item>5.6	Ansible Roles</ListGroup.Item>
                <ListGroup.Item>5.7	Applying configuration using Ansible</ListGroup.Item>
                <ListGroup.Item>5.8	Hands-on Exercise 
                  <ListGroup>
                    <ListGroup.Item>5.8.1	Installing Ansible on AWS</ListGroup.Item>
                    <ListGroup.Item>5.8.2	Creating a Playbook using YAML</ListGroup.Item>
                    <ListGroup.Item>5.8.3	Creating an Ansible Role</ListGroup.Item>
                    <ListGroup.Item>5.8.4	Using Roles in Playbook</ListGroup.Item>
                  </ListGroup>
                </ListGroup.Item>
              </ListGroup>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="5">
              <span className="icon major style1 h5 fa-plus-circle" >    6.	Continuous Testing with Selenium (Week 10, Week 11)</span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="5">
              <Card.Body>
              <ListGroup>
                <ListGroup.Item>6.1	What is Continuous Testing?</ListGroup.Item>
                <ListGroup.Item>6.2	Introduction to Selenium</ListGroup.Item>
                <ListGroup.Item>6.3	Why Selenium?</ListGroup.Item>
                <ListGroup.Item>6.4	Selenium – Webdriver</ListGroup.Item>
                <ListGroup.Item>6.5	Creating Test Cases in Selenium WebDriver (Waits)</ListGroup.Item>
                <ListGroup.Item>6.6	Selenium Integration with Jenkins</ListGroup.Item>
                <ListGroup.Item>6.7	Running Test Cases on Chromium Web Driver</ListGroup.Item>
                <ListGroup.Item>6.8	Hands-on Exercise 
                  <ListGroup>
                    <ListGroup.Item>6.8.1	Using Maven to import dependencies in Eclipse</ListGroup.Item>
                    <ListGroup.Item>6.8.2	Create Sample Test Case for a website using Selenium</ListGroup.Item>
                    <ListGroup.Item>6.8.3	Implementing a headless test in selenium using Chrome WebDriver</ListGroup.Item>
                  </ListGroup>
                </ListGroup.Item>
              </ListGroup>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="6">
              <span className="icon major style1 h5 fa-plus-circle" >    7.	Continuous Orchestration using Kubernetes (Week 12, Week 13)</span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="6">
              <Card.Body>
              <ListGroup>
                <ListGroup.Item>7.1	Introduction to Kubernetes</ListGroup.Item>
                <ListGroup.Item>7.2	Docker Swarm vs Kubernetes</ListGroup.Item>
                <ListGroup.Item>7.3	Revisiting Kubernetes Cluster Architecture</ListGroup.Item>
                <ListGroup.Item>7.4	Spinning up a Kubernetes Cluster on Ubuntu VMs</ListGroup.Item>
                <ListGroup.Item>7.5	YAML Files</ListGroup.Item>
                <ListGroup.Item>7.6	Creating a Deployment in Kubernetes using YAML</ListGroup.Item>
                <ListGroup.Item>7.7	Services in Kubernetes</ListGroup.Item>
                <ListGroup.Item>7.8	Ingress in Kubernetes</ListGroup.Item>
                <ListGroup.Item>7.9	Containers and Container Orchestration</ListGroup.Item>
                <ListGroup.Item>7.10	Hands-on Exercise 
                  <ListGroup>
                    <ListGroup.Item>7.10.1	Setting up the Kubernetes Cluster</ListGroup.Item>
                    <ListGroup.Item>7.10.2	Accessing your application through service</ListGroup.Item>
                    <ListGroup.Item>7.10.3	Deploying an app through Kubernetes Dashboard</ListGroup.Item>
                  </ListGroup>
                </ListGroup.Item>
              </ListGroup>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="7">
              <span className="icon major style1 h5 fa-plus-circle" >    8.	Continuous Monitoring with Nagios (Week 14)</span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="7">
              <Card.Body>
              <ListGroup>
                <ListGroup.Item>8.1	What is Continuous Monitoring</ListGroup.Item>
                <ListGroup.Item>8.2	Introduction to Nagios</ListGroup.Item>
                <ListGroup.Item>8.3	Installing Nagios</ListGroup.Item>
                <ListGroup.Item>8.4	Nagios Plugins (NRPE) and Objects</ListGroup.Item>
                <ListGroup.Item>8.5	Nagios Commands and Notification</ListGroup.Item>
                <ListGroup.Item>8.6	Monitoring System Info using NRPE plugins</ListGroup.Item>
                <ListGroup.Item>8.7	Hands-on Exercise 
                  <ListGroup>
                    <ListGroup.Item>8.7.1	Installing Nagios</ListGroup.Item>
                    <ListGroup.Item>8.7.2	Monitoring of different servers using Nagios</ListGroup.Item>
                  </ListGroup>
                </ListGroup.Item>
              </ListGroup>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="8">
              <span className="icon major style1 h5 fa-plus-circle" >    9.	Introduction to DevOps on Cloud, Interview Prep (Week 15, Week 16)</span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="8">
              <Card.Body>
                <ListGroup>
                  <ListGroup.Item>9.1	DevOps using AWS</ListGroup.Item>
                  <ListGroup.Item>9.2	Various AWS services for DevOps</ListGroup.Item>
                  <ListGroup.Item>9.3	Review of Key Topics</ListGroup.Item>
                  <ListGroup.Item>9.4	Key Tips to Remember for the Interview</ListGroup.Item>
                  <ListGroup.Item>9.5	Mock Interviews</ListGroup.Item>
                  <ListGroup.Item>9.6	Your Personal Preparation and Support for Interviews</ListGroup.Item>
                </ListGroup>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        </article>
      </div>
    </div>
  </Layout>
);

export default DevOpsTrng;
